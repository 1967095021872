import { TaxCode } from '@buy-viasat/types/build/bv';
import { VPPSystemName } from '@buy-viasat/types/build/vpp';
import { VPPBillingAddress } from '../address';

export enum IFrameRadioButton {
  CREDIT_CARD = 'creditCard',
  BANK = 'bank',
}

export enum PaymentType {
  ACH = 'ACH',
  CREDIT_CARD = 'CREDIT_CARD',
  BOLETO = 'BOLETO',
  SEPA = 'SEPA',
}

export enum PaymentMethods {
  DISCOVER = 'Discover',
  VISA = 'Visa',
  MASTERCARD = 'Mastercard',
  AMERICAN_EXPRESS = 'American Express',
  JCB = 'JCB',
  DINERS_CLUB = 'Diners Club',
  SEPA = 'SEPA',
  PAYPAL = 'PayPal',
}

export interface QueryPaymentOnFile {
  systemName: string;
  customerRef: string;
}

export type PaymentOnFile = {
  last4Digits: string;
  paymentMethod: string;
  ccExpYear: number;
  ccExpMonth: number;
};

export interface AuthVoidTransaction {
  authAmount: number;
  paymentOnFileId: string;
  transactionId: string;
}

export type ModifyVPPTransactionPayload = {
  transactionId: string;
  transactionAmount: number;
  billingAddressPayload: VPPBillingAddress;
};

export interface PaymentInformationState {
  transactionId: string;
  spbBillingAccountId: string;
  paymentOnFileId: string;
  taxCode: TaxCode;
  iFrameRadioButton: IFrameRadioButton;
  isPaymentInformationLoading: boolean;
  isPaymentInformationValid: boolean;
  sameAsPrimaryContact: boolean;
  vppCardNameIsValid: boolean;
  vppCardNumberIsValid: boolean;
  vppCardCVVIsValid: boolean;
  vppCardExpirationIsValid: boolean;
  vppCardType: string;
  vppPostMessageSuccess: boolean;
  vppSubmitSaleSuccess: boolean;
  isPaymentInformationLoadingStartTime: number;
  vppPaymentType: PaymentType;
  customerRef: QueryPaymentOnFile['customerRef'];
  systemName: QueryPaymentOnFile['systemName'];
  paymentOnFile: PaymentOnFile;
  regulatedSPBBillingAccountId: string;
  nonRegulatedSPBBillingAccountId: string;
  smbBillingAccountId: string;
  vpp3DSMode: boolean;
  isIframeLoading: boolean;
  vppBankValidationSuccess: boolean;
  modifyVPPTransactionSuccess: boolean;
}

export const initialPaymentInformationState: PaymentInformationState = {
  transactionId: '',
  spbBillingAccountId: '',
  paymentOnFileId: '',
  taxCode: { taxCodeType: '', label: '', taxCodeValue: '' } as TaxCode,
  iFrameRadioButton: IFrameRadioButton.CREDIT_CARD,
  isPaymentInformationLoading: false,
  isPaymentInformationValid: true,
  sameAsPrimaryContact: false,
  vppCardNameIsValid: false,
  vppCardNumberIsValid: false,
  vppCardCVVIsValid: false,
  vppCardExpirationIsValid: false,
  vppCardType: '',
  vppPostMessageSuccess: false,
  vppSubmitSaleSuccess: false,
  isPaymentInformationLoadingStartTime: 0,
  vppPaymentType: PaymentType.ACH,
  paymentOnFile: { last4Digits: '', ccExpMonth: 0, ccExpYear: 0, paymentMethod: '' },
  customerRef: '',
  systemName: VPPSystemName.US_RESIDENTIAL,
  regulatedSPBBillingAccountId: '',
  nonRegulatedSPBBillingAccountId: '',
  smbBillingAccountId: '',
  vpp3DSMode: false,
  isIframeLoading: false,
  vppBankValidationSuccess: false,
  modifyVPPTransactionSuccess: false,
};
