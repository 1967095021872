import { BVEnv } from '@buy-viasat/env-files';

const env: BVEnv = {
  "env": "prod",
  "serverUrls": {
    "auth": "https://buy.viasat.com/auth",
    "graphql": "https://buy.viasat.com/graphql",
    "places": "https://buy.viasat.com/places"
  },
  "redirectUrls": {
    "customerFeedbackUrl": "https://viasat.az1.qualtrics.com/jfe/form/SV_5Bi4QKuCxNTKZRb",
    "myViasatLoginUrl": "https://my.viasat.com",
    "registerNewUserUrl": "https://qax1-myviasat.cs32.force.com/SVSMyViasatNewUser"
  },
  "adobeLaunchScript": "https://assets.adobedtm.com/138ddf5a8359/0880e83f9587/launch-ba9586140784.min.js",
  "supportPhoneNumber": "855-627-2555",
  "flags": {
    "comingSoon": false,
    "stopOnPlans": false,
    "disableAddOns": true,
    "disableSpecialChar": true,
    "disableVppIFrame": false,
    "hideUpsellHeroRegistration": true,
    "ignoreRouteOrder": false,
    "allowQueryStringFeatureOverride": false,
    "shouldLogRaygunAnalyticsOverride": false
  },
  "mocks": {
    "creditVerification": false,
    "personalInformation": false,
    "serviceAddress": false
  },
  "debug": {
    "showAnalytics": false
  },
  "vppIFrame": {
    "dataSecret": "IPETW8BU75EQIZA84KF9QL8ZR449ECSP",
    "url": "https://amer.payments.viasat.com/RfeMyViasatFrameV2",
    "euUrl": "https://eu.payments.viasat.com/RfeBuyViasatFrame"
  },
  "googleApiKey": "AIzaSyC-AJ7ooc31557VYQAnD0iqdxiiZIm2K7U",
  "pixel": {
    "namespace": "buy-viasat-res-prod",
    "cookieDomain": "viasat.io"
  },
  "aemUrl": "https://www.viasat.com/content/experience-fragments/viasat",
  "buildVersion": "3b6ef4fb6",
  "raygunApiKey": "5TGKIdyIeds7ENsLaA1eCw",
  "adobeTargetScript": "https://assets.adobedtm.com/138ddf5a8359/62b57fc71b22/launch-370d9929035b.min.js"
};


export default env;
