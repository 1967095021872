export const schema = `
schema {
  query: Query
  mutation: Mutation
}

directive @noAlias(allow: Int = 1) repeatable on OBJECT | FIELD_DEFINITION

enum ActionEnum {
  EXTEND_CART_ITEM
  ADD_PORTFOLIO
  EXTEND_PORTFOLIO_ITEM
  REPLACE_PORTFOLIO_ITEM
  ADD_CART_ITEM
  REMOVE_CART_ITEM
  UPDATE_STATUS
}

input AddAddressInput {
  primary: AddressInput
  secondary: AddressInput
  invoice: AddressInput
  shipping: AddressInput
  support: AddressInput
}

input AddBillingAccountInput {
  recurringPaymentMethodType: String!
  relnId: ID!
  taxCode: TaxCodeInput!
  partyId: ID!
  isRegulated: Boolean
  accountNumbersToRemove: [String]
  isBusinessAccount: Boolean
  isEndUserBilled: Boolean
  isExemptFromLateFee: Boolean
  isExemptFromTaxation: Boolean
}

enum AddContactMethodError {
  Address
  Email
  IdInputRequired
  Phone
}

input AddEmailInput {
  primary: String
  Secondary: String
  Invoice: String
  Support: String
}

type AddOn {
  id: String!
  name: String!
  description: String
  subHeading: String
  kind: String
  price: Float!
  promo: ProductPromo!
  selected: Boolean
  shortName: String
  noAddAttributes: [String]
  attributes: [String]
  annualSavings: String
  tag: String
  crossOuts: [String]
  disclosure: String
  offerId: String
  displayOrder: Int
  isBundle: Boolean
}

input AddPhoneInput {
  primary: String
  secondary: String
  invoice: String
  support: String
}

input AddRelationshipInstanceInput {
  relnTypeName: ID!
  groups: [String!]!
  roles: [PartyRoleInput!]!
}

input AddRelnEditInput {
  destinationId: ID!
  destinationType: ProductIdType!
  sourceType: ProductIdType!
  relnType: String!
  sourceId: ID!
}

input AddRelnEditR0 {
  destinationId: String!
  destinationType: String!
  relnType: String!
  sourceType: String!
}

enum AddonType {
  EasyCare
  Voice
  Shield
  StreamOn
  WorkFromHome
  StaticIp
}

type AddressCSC {
  addressLines: [String!]!
  municipality: String!
  region: String!
  countryCode: String!
  postalCode: String
}

input AddressCSCR0 {
  addressLines: [String!]!
  municipality: String!
  region: String!
  postalCode: String
  countryCode: String
}

type AddressDetailsPayload {
  addressDetails: FilteredAddressSuggestion
  status: GooglePlacesStatusCode!
}

type AddressGeometry {
  latitude: Float!
  longitude: Float!
}

input AddressInput {
  addressLines: [String!]!
  countryCode: String!
  municipality: String!
  postalCode: String!
  region: String
}

input AddressInputFO {
  addressLine: [String!]!
  municipality: String!
  region: String!
  postalCode: String
  countryCode: String!
}

input AddressInputOM {
  addressLines: [String]
  municipality: String
  region: String
  countryCode: String!
  postalCode: String
  houseNumber: String
}

input AddressInputPartners {
  addressLines: [String!]!
  municipality: String!
  region: String!
  postalCode: String!
  countryCode: String
}

input AddressInputR0 {
  addressLines: [String!]!
  municipality: String!
  region: String
  countryCode: String!
  postalCode: String!
}

type AddressR0 {
  addressLines: [String!]!
  municipality: String!
  region: String!
  country: String
  postalCode: String!
  countryCode: String
}

type AddressSuggestion {
  matchedSubstrings: [MatchedSubstring!]!
  placeId: String!
  description: String!
}

type AddressSuggestionsPayload {
  suggestions: [AddressSuggestion]!
  status: GooglePlacesStatusCode!
}

input AemErrorParams {
  errorCode: String
}

type Amount {
  value: Float
  currency: [CurrencyType]
}

input Appointment {
  startTime: String!
  endTime: String!
}

type Attribute {
  name: String!
  value: String!
  valueType: String
}

input AttributeInputFO {
  name: String!
  value: String!
}

input AttributeInputR0 {
  name: String!
  value: String!
  type: String
}

type AttributeR0 {
  name: String!
  value: String
  type: String
}

input AuthAndVoidCardPaymentVerificationInput {
  spbBillingAccountId: String
  txnAmount: Int
  userAgent: String
  customerRef: String
  paymentOnFileId: String!
  transactionId: String
}

type AvailableAppointmentsPayloadR0 {
  timezone: String
  schedules: [ScheduleR0]!
}

type BehaviorClass {
  behavior: String!
  value: String
}

type BillingAccount {
  accountNumber: ID!
  accountGroupNumber: ID!
  invoicingOrganization: InvoicingOrganization!
  paymentReference: PaymentReference!
  recurringPaymentMethodType: String
  billingPIIRefId: ID!
  mailingPIIRefId: ID
  piiFileLocationId: ID!
  nextBillPeriodStartDate: Date
  billingCycleDayOfMonth: Int
  currentBalance: Money
  totalAmountUnbilledOneTimeCharges: Money
  totalAmountUnbilledAdjustments: Money
  totalAmountPendingDisputes: Money
  accountStatus: String
  totalAmountPastDue: Money
  totalDaysPastDue: Int
  balanceForward: Money
  isBusinessAccount: Boolean
  isExemptFromLateFee: Boolean
  isExemptFromTaxation: Boolean
  taxCode: TaxCode
}

type BillingAccountResponse {
  billingAccount: BillingAccount
}

type BusinessUnit {
  businessUnitId: ID!
  businessUnitName: String
}

type CartCharacteristics {
  name: String
  value: String
}

type CartItems {
  plan: CartProduct
  addons: [CartProduct]!
  closingOffers: [CartProduct]
}

type CartMarketingCopy {
  uiBehaviors: [CartUIBehaviors]
  translations: [CartTranslations!]
}

type CartPackageTypes {
  id: String
  name: String
  kind: String
  description: String
  productTypes: [CartProductTypes!]!
}

type CartProduct {
  categoryName: String
  name: String
  productCandidateId: String
  productTypeId: String
  price: Float
  promo: Promo
  accountSetupFeeShortName: String
  accountSetupFeePrice: Float
  contractTerms: String
  contractTermsDisclosure: String
  leaseFeeShortName: String
  leaseFeePrice: Float
  displayOrder: Float
}

type CartProductTypes {
  id: String
  name: String
  kind: String
  marketingCopy: [CartMarketingCopy!]
}

type CartTranslations {
  characteristics: [CartCharacteristics!]
}

type CartUIBehaviors {
  characteristics: [CartCharacteristics]!
}

type Characteristic {
  name: String!
  value: String
  valueType: String
}

input CharacteristicInput {
  name: String!
  value: String!
  valueType: String
}

type ClosingOffer {
  id: ID!
  name: String!
  price: Float!
  promo: ProductPromo
}

input ContactMethodFilterInput {
  address: OptionalAddressInput
  email: String
  phoneNumber: String
  mobileNumber: String
}

enum ContactMethodLabel {
  Primary
  Secondary
  Invoice
  Shipping
  Support
}

input Coordinates {
  latitude: Float!
  longitude: Float!
}

input CoordinatesInput {
  latitude: Float!
  longitude: Float!
}

input CoordinatesInputFO {
  latitude: Float!
  longitude: Float!
}

type CreateContractInstance {
  contractId: String
  customerId: String!
  productInstanceId: String
  firstName: String!
  lastName: String!
  phoneNumber: String!
  email: String!
  addressLine1: String!
  addressLine2: String!
  contractTemplateId: String!
  inviteSigner: Boolean
  isAccepted: Boolean
}

enum CreateCustomerError {
  AddIndividual
  AddOrganization
  AddressInputRequired
  RelationshipId
}

input CreateCustomerInput {
  individual: CreateIndividualInput
  organization: CreateOrganizationInput
}

type CreateCustomerResponse {
  isCustomer: Boolean!
  existingContactMethod: ExistingContactMethod
  individualPartyId: String!
  organizationPartyId: String!
  relnId: String!
}

input CreateIndividualInput {
  firstName: String!
  lastName: String!
  email: String!
  phoneNumber: String!
  mobileNumber: String
  serviceAddress: AddressInput!
  billingAddress: AddressInput
  shippingAddress: AddressInput
  taxId: String
}

input CreateOrganizationInput {
  organizationName: String!
  firstName: String!
  lastName: String!
  email: String!
  phoneNumber: String!
  mobileNumber: String
  serviceAddress: AddressInput!
  billingAddress: AddressInput
  shippingAddress: AddressInput
  taxId: String
  isTaxIdSsn: Boolean
  isTaxExempt: Boolean
}

enum CreateSalesOrderError {
  CreateShoppingCart
  SalesOrder
  Timeout
  WorkOrder
}

input CreateSalesOrderInput {
  cartId: ID!
  customerRelationshipId: String!
  productIds: [String!]
  workOrder: WorkOrderInput
}

type CreateSalesOrderPayloadR0 {
  order: Order
  workOrder: ScheduleWorkOrderPayloadR0
}

enum CreditCheckError {
  NoResultsFound
  InternalServerError
}

type Currency {
  alphabeticCode: String
  numericCode: Float
  minorUnits: Int
}

type CurrencyType {
  name: String
}

type Customer {
  isCustomer: Boolean!
  existingContactMethod: ExistingContactMethod
  partyId: String!
  relnId: String!
}

input CustomerInfoMarketing {
  firstName: String
  lastName: String
  email: String
  serviceAddress: String
  phoneNumber: String
}

input CustomerParams {
  dealerId: String
  partnerId: String
  campaignId: String
}

type DataAccessGroup {
  groupName: ID!
  description: String!
}

scalar Date

input DateOfBirth {
  month: String!
  day: String!
  year: String!
}

scalar DateTime

type DigitalService {
  iconUrl: String
}

type DiscountType {
  totalDiscounts: [TotalDiscount]
}

input EditInstallationDateInput {
  externalWorkOrderId: ID!
  schedule: ScheduleInput!
}

enum EditTypeR0 {
  AddReln
  ReplaceProdCandidateEdit
  RmProdCandidate
  RmReln
}

enum ErrorTypes {
  PAYMENT_AUTH_ERROR
}

enum ExistingContactMethod {
  address
  email
  phoneNumber
}

type ExternalId {
  type: ExternalIdType!
  value: String!
}

type ExternalIdType {
  description: String!
  groups: [DataAccessGroup!]!
  pattern: String
  typeName: String!
}

type FeatureFlags {
  singleFieldAddress: Boolean
  allowChoosePlan: Boolean
  serviceabilityAptSte: Boolean
  scheduleInstallLocation: String
  onSiteContactInformation: Boolean
  billingContactInformation: Boolean
  displayAddonsPage: Boolean
  isSSNMandatory: Boolean
  authAmount: Int
  boletoExpirationDays: Int
  enableMarketingConsent: Boolean
  showShippingAddress: Boolean
  sellingPoint: String
  displayViewMoreDetailsLink: Boolean
  topBarOptions: String
  displayPhoneNumber: Boolean
  hideScrubAddressModal: Boolean
  allowChooseVoice: Boolean
  displayTooltipPlanGrid: Boolean
  displayCreditCheckPage: Boolean
  pricePlanCard: String
  displayHouseNumber: Boolean
  signupMyViasat: Boolean
  displayRemarketingCheckbox: Boolean
  displayNextSteps: Boolean
  workOrderCharacterLimit: Int
  dishNetworkPartnerInfo: [String]
  shouldDisplayDueToday: Boolean
  displaySmbLink: Boolean
  displayNewPlanCards: Boolean
  maintenanceMode: Boolean
  displayAcpBeforeCreditCheck: Boolean
  enablePlaceMyOrderFromPaymentPage: Boolean
  displayTotalSavingsTag: Boolean
  shouldLogRaygunAnalytics: Boolean
  skeletonLoaderForContactInformation: Boolean
  enableIraNewEndpoint: Boolean
  strictPhoneNumberValidation: Boolean
  hideAcpCheckbox: Boolean
  displayingTooltipOnBvPlanCards: Boolean
  enablingClosingOffersInBV: Boolean
  enableBroadbandLabels: Boolean
  displayButtonLabels: Boolean
  enableSubmitOrderPALTransition: Boolean
  enablePalApis: Boolean
  showBankAndCCPreauthMessage: Boolean
}

input FeatureFlagsInput {
  userKey: ID
  isPartner: Boolean
  customerParams: CustomerParams
}

type FeesAndDiscounts {
  name: String
  fee: Float
  discount: Float
  total: Float
  kind: String!
}

type FilteredAddressSuggestion {
  formattedAddress: String!
  geometry: AddressGeometry!
  structuredAddress: AddressCSC!
}

type FilteredAddressesPayload {
  suggestions: [FilteredAddressSuggestion]!
  status: GooglePlacesStatusCode!
}

type FulfillmentAgreement {
  fulfillmentAgreementId: ID!
  fulfillmentAgreementName: String!
  salesPartner: SalesPartner!
  fulfillmentPolicy: FulfillmentPolicy!
  masterAgent: MasterAgent!
  businessUnit: BusinessUnit!
  attributes: [Attribute!]
}

type FulfillmentIncentivePolicy {
  fulfillmentIncentivePolicyId: ID!
  workOrderType: WorkOrderType!
  productKinds: [ProductKind!]!
}

type FulfillmentPolicy {
  fulfillmentPolicyId: ID!
  fulfillmentPolicy: String!
  fulfillmentRoutingPolicy: FulfillmentRoutingPolicy!
  fulfillmentIncentivePolicies: [FulfillmentIncentivePolicy!]!
  fulfillmentTerritory: FulfillmentTerritory!
  territoryDefault: Boolean!
}

type FulfillmentRoutingPolicy {
  fulfillmentRoutingPolicyId: ID!
  fulfillmentRoutingPolicy: String!
  fulfillmentSystem: FulfillmentSystem!
}

type FulfillmentSystem {
  fulfillmentSystemId: ID!
  fulfillmentSystem: String!
}

type FulfillmentTerritory {
  fulfillmentTerritoryId: ID!
  fulfillmentTerritory: String!
  defaultFulfillmentPolicy: FulfillmentPolicy!
}

input GetAddOnsInput {
  location: LocationInput!
  rootProductId: ID!
  dealerId: String
  partnerId: String
  offerId: String
  salesAgreementId: String!
  productSegment: String
}

input GetAvailableAppointments {
  from: String!
  to: String!
  address_lines: String!
  municipality: String!
  region: String!
  country_code: String!
  postal_code: String
  latitude: String
  longitude: String
  fulfillment_agreement_id: String
  root_product_instance_id: String
  shopping_cart_id: String
}

input GetAvailableExtensionProductsInputR0 {
  salesAgreementId: String
  location: LocationInput!
  rootProductId: String!
  productRelationship: String!
  productSegment: String
}

enum GetAvailableProductsError {
  Fulfillment
  Product
}

input GetAvailableProductsInputR0 {
  salesAgreementId: String
  location: LocationInput!
  creditCheckResults: [AttributeInputR0]
  additionalAttributes: [AttributeInputR0]
  customerPartyId: String
  mode: String
  dealerId: String
  partnerId: String
  productSegment: String
  customerType: String
  offerId: String
}

input GetAvailableWorkOrderSchedulesFilters {
  from: LocalDateTime!
  to: LocalDateTime!
}

input GetAvailableWorkOrderSchedulesInput {
  serviceLocation: LocationInputFO!
  fulfillmentAgreementId: ID!
  products: [ThinProductInput!]!
}

type GetAvailableWorkOrderSchedulesPayload {
  schedules: [Schedule!]
  timezone: String!
}

input GetClosingOffersInput {
  location: LocationInput!
  rootProductId: String!
  offerId: String
  salesAgreementId: String
  productIds: [String!]!
}

type GetCurrentCustomerRelationshipsIdName {
  id: String
  name: String
}

input GetCurrentCustomerRelationshipsInputR0 {
  productInstanceId: String!
}

type GetCurrentCustomerRelationshipsProductFulfillmentAgreement {
  id: String
  name: String
  isActive: Boolean
  fulfillmentPolicy: GetCurrentCustomerRelationshipsIdName
  selfFulfilling: Boolean
}

type GetCurrentCustomerRelationshipsResponse {
  productInstanceId: String
  state: String
  relnId: String
  salesAgreementId: String
  lifecycleState: String
  salesAgreement: GetCurrentCustomerRelationshipsSalesAgreeement!
  productFulfillmentAgreement: GetCurrentCustomerRelationshipsProductFulfillmentAgreement
}

type GetCurrentCustomerRelationshipsSalesAgreeement {
  id: String
  name: String
  salesPolicy: GetCurrentCustomerRelationshipsSalesPolicy
  salesPartner: GetCurrentCustomerRelationshipsIdName
  masterAgent: GetCurrentCustomerRelationshipsIdName
  salesRelationshipType: String
  selfFulfilling: Boolean
  dealerId: String
}

type GetCurrentCustomerRelationshipsSalesPolicy {
  businessUnit: GetCurrentCustomerRelationshipsIdName
  id: String
  name: String
}

type GetCustomerPayload {
  isCustomer: Boolean!
  existingContactMethod: ExistingContactMethod
}

type GetCustomerResult {
  fullName: String
  emails: [String]
  addresses: [AddressCSC]
  phoneNumbers: [String]
}

input GetFulfillmentAgreementsInput {
  fulfillmentAgreementId: ID
  fulfillmentAgreementName: String
  salesPartnerId: ID
  salesPartnerName: String
  fulfillmentPolicyId: ID
  fulfillmentPolicyName: String
  masterAgentId: ID
  masterAgentName: String
  businessUnitId: ID
  businessUnitName: String
  attributes_contains: [AttributeInputFO!]
  location: LocationInputFO
}

type GetFulfillmentAgreementsPayload {
  fulfillmentAgreements: [FulfillmentAgreement!]
}

input GetInstallationDatesBVInput {
  address: AddressCSCR0
  from: String
}

input GetInstallationDatesInputR0 {
  cartId: ID!
  location: LocationInput
  from: String!
  to: String
  dealerId: String
  partnerId: String
  salesAgreementId: String!
  fulfillmentAgreementId: String!
}

input GetInstallationFeeR0 {
  customerPartyId: ID!
  location: LocationInput!
  creditCheckResults: [AttributeInputR0!]!
  productId: String!
  cartId: ID!
  dealerId: String
  partnerId: String
  subsidyApplicationId: String
  salesAgreementId: String!
  offerId: String
  dob: String!
}

input GetOrderStateInput {
  orderId: ID!
}

input GetPartnerAccountInputR0 {
  location: LocationInputR0!
  businessUnitPartyId: String
  personaRole: String
  partnerIraPartyId: String
  isReferral: String
  dealerId: String
}

input GetPaymentOnFileInput {
  systemName: String
  customerRef: String
}

type GetPaymentOnFilePayload {
  last4Digits: String
  paymentMethod: String
  ccExpYear: Int
  ccExpMonth: Int
}

input GetWorkOrderInput {
  externalWorkOrderId: String!
}

type GetWorkOrderPayload {
  status: String!
}

enum GooglePlacesStatusCode {
  OK
  ZERO_RESULTS
  OVER_QUERY_LIMIT
  REQUEST_DENIED
  INVALID_REQUEST
  UNKNOWN_ERROR
}

enum Group {
  GBS
  USResidential
  USBusiness
  BrazilResidential
  ItalyResidential
  EUResidential
}

enum InputAttributeName {
  SSN
  DOB
  COUNTRY
}

type InstallationDatesResponse {
  timezone: String
  schedules: [ScheduleR0]
}

type InstallationFeeR0 {
  value: Float
}

type InvoicingOrganization {
  invoicingOrgId: ID!
  name: String!
  description: String!
}

type ItemDiscount {
  discountItem: Promo
}

scalar LocalDateTime

input LocationInput {
  coordinates: CoordinatesInput
  address: AddressInputOM
}

input LocationInputFO {
  coordinates: CoordinatesInputFO
  address: AddressInputFO
}

input LocationInputR0 {
  coordinates: CoordinatesInput
  address: AddressInputPartners!
}

type LogUrlParamsResponse {
  urlParamsLogged: Boolean
}

type MarketingCopy {
  name: String
  language: String
  characteristics: [AttributeR0]
}

type MasterAgent {
  masterAgentId: ID!
  masterAgentName: String
}

type MatchedSubstring {
  length: Int!
  offset: Int!
}

type ModifyVPPTransactionPayload {
  message: String
}

type Money {
  value: Float
  currency: Currency
}

type Mutation @noAlias {
  addBillingAccount(input: AddBillingAccountInput): BillingAccount
  addBillingAccountAndCustReln(relnId: ID!, setStringSetAttrs: [StringSetAttributeInput!]): RelationshipInstance
  addContactMethods(input: UpdateContactMethodInput!): Success!
  addRelationshipInstance(relnTypeName: ID!, groups: [String!]!, roles: [PartyRoleInput!]!): RelationshipInstance
  createCustomer(input: CreateCustomerInput!): CreateCustomerResponse
  createIndividual(input: CreateIndividualInput!): Customer
  createOrganization(input: CreateOrganizationInput!): SmbCustomer
  createSalesOrder(input: CreateSalesOrderInput!): CreateSalesOrderPayloadR0!
  editInstallationDate(input: EditInstallationDateInput!): Boolean!
  modifyVPPTransaction(input: UpdateVPPTransactionInput!): ModifyVPPTransactionPayload
  removeCartItems(input: RemoveCartItems!): ShoppingCart
  sendLeadToMarketo(input: CustomerInfoMarketing): SendLeadToMarketoResponse
  submitFullOrder(input: SubmitFullOrderInput): SubmitFullOrderPayload
  submitOrder(input: OrderInput!): Order
  updateBillingAccount(input: UpdateBillingAccountInput!): BillingAccount
  updateIndividual(input: UpdateIndividualInput!): UpdateIndividualResponse
  updateMarketingPreferences(input: UpdateMarketingPreferences): UpdateMarketingPreferencesResponse
  updateOrganization(input: UpdateOrganizationInput!): UpdateOrganizationResponse
  updateRelationshipInstance(input: UpdateRelationshipIdInput): BillingAccount
  upsertCartAddons(input: ShoppingCartAddonsInput!): ShoppingCart
  upsertCartPlan(input: ShoppingCartPlanInput!): ShoppingCart
}

input NLADBenefitsInput {
  partyId: String
  region: String
  dateOfBirth: String
  applicationId: String
  transactionType: String
  tribalBenefit: Boolean
}

type NLADBenefitsResponse {
  message: String
  name: String
  errorType: String
  correctiveAction: String
  traceId: String
}

input NoteInputR0 {
  content: String!
  author: String
  createTime: String
}

type NoteR0 {
  content: String!
  author: String
  createTime: String
}

type NullableAddress {
  addressLine: [String]
  municipality: String
  region: String
  postalCode: String
  countryCode: String
}

input OFMAddress {
  addressLines: [String]!
  municipality: String!
  region: String!
  countryCode: String!
  postalCode: String!
}

input OFMLocation {
  coordinates: Coordinates!
  address: OFMAddress!
}

type OFMPackageTypes {
  id: String
  name: String
  kind: String
  packageTypes: [CartPackageTypes!]!
  productTypes: [PackageProductTypes!]
  characteristics: [CartCharacteristics!]
  marketingCopy: [CartMarketingCopy!]
}

input OnSiteContactInformation {
  firstName: String
  lastName: String
  email: String
  phoneNumber: String
}

input OptionalAddressInput {
  addressLines: [String!]
  municipality: String
  region: String
  country: String
  postalCode: String
  countryCode: String
}

type Order {
  orderId: ID!
  state: OrderLifecycleState
}

input OrderAttributeInput {
  valueType: String
  name: String!
  value: String!
}

input OrderInput {
  orderId: ID!
  customerRelationshipId: ID!
  salesAgreementId: ID
  fulfillmentAgreementId: ID
  orderLines: [OrderLineItemInput!]
  shoppingCartId: ID!
  state: OrderLifecycleState
  productInstanceIds: [ID!]
  productCandidateGraphEdits: [ProductCandidateGraphEditInput!]
  validationSignature: String
  paymentTransactionId: ID
  executionDate: DateTime
  serviceLocation: LocationInput
  """OrderAttributes can consist of arbitrary business unit specific information that is opaque to OM."""
  attributes: [OrderAttributeInput!]
}

input OrderInputR0 {
  orderId: String
  customerRelationshipId: String!
  productCandidateGraphEdits: [ProductCandidateGraphEditR0]
  orderLines: [OrderLineInputR0]
  attributes: [AttributeInputR0]
  serviceLocation: ServiceLocationR0
  paymentTransactionId: String
  executionDate: String
  shoppingCartId: String
}

enum OrderLifecycleState {
  Canceled
  Canceling
  CancelingError
  Closed
  Initializing
  InitializingError
  Open
  OpenError
  Processed
  ProcessedError
  Rejected
  Scheduled
}

enum OrderLineEvent {
  Add
  Return
  Transition
}

enum OrderLineEventR0 {
  Add
  Return
  Transition
}

input OrderLineInputR0 {
  orderLineId: String!
  orderLineEvent: String!
  productInstanceId: String
  shoppingCartItemId: String
  contractId: String
  orderLineItemId: String
  characteristics: [AttributeInputR0]
}

input OrderLineItemInput {
  productInstanceId: ID
  orderLineId: ID
  orderLineItemId: ID
  orderLineEvent: OrderLineEvent!
  orderLineAttributes: [OrderAttributeInput!]
  shoppingCartItemId: ID
  characteristics: [CharacteristicInput]
  serviceLocation: LocationInput
  contractId: ID
}

type OrderLineR0 {
  orderLineId: String!
  orderLineEvent: String!
  productInstanceId: String
  shoppingCartItemId: String
  contractId: String
  orderLineItemId: String
  attributes: [AttributeR0]
  characteristics: [AttributeR0]
  state: String
}

type OrderR0 {
  orderId: String!
  customerRelationshipId: String!
  productInstanceIds: [String]
  serviceLocationId: String
  state: String
  attributes: [AttributeR0]
  orderLines: [OrderLineR0]
}

type PackageAmount {
  value: String
  currency: PackageCurrency
}

type PackageCurrency {
  name: String
  alphabeticCode: String
}

type PackagePrices {
  name: String
  kind: String
  description: String
  amount: [PackageAmount!]
  recurrence: String
}

type PackageProductTypes {
  id: String
  kind: String
  name: String
  marketingCopy: [ProductMarketingCopy!]
  prices: [PackagePrices!]
}

type PackageType {
  id: String
  name: String
  kind: String
  description: String
  minSelections: Int
  maxSelections: Int
  productTypes: [ProductTypeR0]
  marketingCopy: [MarketingCopy]
  characteristics: [AttributeR0]
  serviceTypes: [ServiceType]
  packageTypes: [PackageType]
}

type PartnerAccountResponse {
  salesAgreementId: String
  fulfillmentAgreementId: String
}

input PartyRoleInput {
  partyId: ID!
  roleName: String!
}

enum PartyType {
  Individual
  Organization
}

type PaymentReference {
  value: String!
  type: PaymentReferenceType!
}

type PaymentReferenceType {
  name: ID!
  description: String!
}

input PerformCreditCheckInput {
  partyId: String!
  ssn: String!
  dob: DateOfBirth!
  serviceLocation: LocationInput!
  productId: String!
  cartId: ID!
  taxId: String
  dealerId: String
  partnerId: String
  subsidyApplicationId: String
  salesAgreementId: String!
  offerId: String
}

type PerformCreditCheckResponse {
  installationFee: Float
  isEligible: Boolean
  equifaxTransactionId: String
  creditTag: String
  feesAndDiscounts: [FeesAndDiscounts!]
}

type PlanCharacteristics {
  dataCapUnits: String
  dataCap: String
  uploadSpeed: String
  uploadUnits: String
  downloadSpeed: String
  downloadSpeedText: String
  downloadUnits: String
  displayOrder: Int
  freeZone: String
  resolution: String
  productFamily: String
  textGeneral: String
  textGeneral01: String
  inflectionPointText: String
  dataAllowanceText: String
  shortName: String
  tag: String
  titleAddOns: String
  isRegulated: String
  routerText: String
  attribute1: String
  attribute2: String
  benefits: String
  bannerColor: String
  serviceType: String
  imageOneUrl: String
  contractTerm: String
  contractTermUnit: String
  feeText: String
  downloadRange: String
  uploadSpeedText: String
  typicalDownloadSpeed: String
  upi: String
  serviceAreaId: String
}

type Price {
  name: String!
  description: String
  kind: String!
  recurrence: String!
  amount: Money!
  characteristics: [Characteristic]
}

type Product {
  id: String!
  name: String!
  characteristics: PlanCharacteristics!
  price: Float!
  extensionTypes: [String]
  promo: Promo
  bestFor: String
  isCafII: Boolean
  displayPromoBanner: Boolean
  totalDiscount: Promo
  itemizedDiscounts: ItemDiscount
  offerId: String
  digitalServices: [DigitalService!]
}

input ProductCandidateGraphEditInput {
  rmProdCandidateEdit: RmProdCandidateEditInput
  editType: ProductCandidateGraphEditType!
  addRelnEdit: AddRelnEditInput
  id: ID!
  rmRelnEdit: RmRelnEditInput
  replaceProdCandidateEdit: ReplaceProdCandidateEditInput
}

input ProductCandidateGraphEditR0 {
  id: String!
  addRelnEdit: AddRelnEditR0
  editType: String
}

enum ProductCandidateGraphEditType {
  AddReln
  ReplaceProdCandidateEdit
  RmProdCandidate
  RmReln
}

enum ProductIdType {
  ProductCandidateId
  ProductInstanceId
}

type ProductKind {
  productKindId: ID!
  productKind: String!
}

type ProductMarketingCopy {
  translations: [ProductMarketingTranslations!]
  uiBehaviors: [CartUIBehaviors!]
}

type ProductMarketingTranslations {
  name: String
  language: String
  characteristics: [CartCharacteristics!]
}

type ProductPromo {
  duration: Int!
  price: Float!
  unit: String
}

type ProductType {
  id: ID!
  name: String!
  description: String
  kind: String!
  characteristics: [Characteristic!]!
  marketingCopy: [CartMarketingCopy!]
  packageTypes: [OFMPackageTypes!]!
  prices: [Price!]
  isRoot: Boolean!
  products: [ProductType!]
  uiBehaviors: [BehaviorClass!]
}

type ProductTypeR0 {
  id: String
  name: String
  description: String
  serviceTypes: [ServiceType]
  packageTypes: [PackageType]
  prices: [Price]
  kind: String
  marketingCopy: [MarketingCopy]
  characteristics: [AttributeR0]
  discounts: [DiscountType]
}

type ProductTypesR0 {
  productTypes: [ProductTypeR0]!
  message: String
  code: String
}

type Promo {
  price: Float!
  duration: Int!
}

type Query @noAlias {
  authAndVoidCardPaymentVerification(input: AuthAndVoidCardPaymentVerificationInput): VPPAuthVoidResponse
  createVPPTransaction(input: UpdateVPPTransactionInput!): VPPTransactionPayload
  featureFlags(input: ID): FeatureFlags @deprecated(reason: "Use getFeatureFlags instead")
  getAddOns(input: GetAddOnsInput!): [AddOn]!
  getAddressDetails(placeId: String!): AddressDetailsPayload!
  getAddressSuggestions(input: String!): AddressSuggestionsPayload!
  getAvailableProducts(input: GetAvailableProductsInputR0): [Product]
  getAvailableWorkOrderSchedules(input: GetAvailableWorkOrderSchedulesInput!, filters: GetAvailableWorkOrderSchedulesFilters!): GetAvailableWorkOrderSchedulesPayload
  getCart(input: ID!): ShoppingCart
  getClosingOffers(input: GetClosingOffersInput): [ClosingOffer]
  getCustomer(input: ContactMethodFilterInput!): GetCustomerPayload!
  getFeatureFlags(input: FeatureFlagsInput): FeatureFlags
  getFilteredAddresses(input: String!): FilteredAddressesPayload!
  getFulfillmentAgreements(input: GetFulfillmentAgreementsInput!): GetFulfillmentAgreementsPayload
  getInstallationDates(input: GetInstallationDatesInputR0): AvailableAppointmentsPayloadR0
  getInstallationFee(input: GetInstallationFeeR0): InstallationFeeR0
  getOrderState(input: GetOrderStateInput!): Order
  getPartnerAccount(input: GetPartnerAccountInputR0): PartnerAccountResponse
  getPaymentOnFile(input: GetPaymentOnFileInput!): GetPaymentOnFilePayload
  getTaxCodes(input: LocationInput!): [TaxCode]!
  getWorkOrder(input: GetWorkOrderInput!): GetWorkOrderPayload!
  logAemErrors(input: AemErrorParams): Boolean
  logResetSession(input: String): Boolean
  logUrl(input: String): Boolean
  logUrlParams(input: QueryParams): Boolean
  performCreditCheck(input: PerformCreditCheckInput): PerformCreditCheckResponse!
  retrieveVPPPaymentTransaction(input: RetrieveVPPPaymentTransactionInput): VPPRetrievePaymentPayload
  scrubAddress(input: ScrubAddressInput!): ScrubAddressPayload!
  updateBillingInformationBV(input: UpdateBillingFormationInput): VPPTransactionPayload
  updateVPPTransaction(input: UpdateVPPTransactionInput): VPPTransactionPayload
  verifyNLAD(input: NLADBenefitsInput): NLADBenefitsResponse
}

input QueryParams {
  dealerId: String
  partnerId: String
  affiliateId: String
  sessionId: String
  campaignId: String
  addressLine1: String
  addressLine2: String
  municipality: String
  region: String
  postalCode: String
  countryCode: String
  plnId: String
  hideCta: Boolean
  offerId: String
}

type RelationshipInstance {
  relnId: ID!
}

input RemoveCartItems {
  cartId: ID!
  productCandidateIds: [ID!]!
}

input ReplaceProdCandidateEditInput {
  newProductInstanceId: ID!
  oldProductCandidateId: ID!
}

enum ResponseAttributeName {
  IS_ELIGIBLE
  EQUIFAX_TRANSACTION_ID
  CREDIT_TAG
}

input RetrieveVPPPaymentTransactionInput {
  transactionId: String!
}

input RmProdCandidateEditInput {
  productCandidateId: ID!
}

input RmRelnEditInput {
  relnId: ID!
}

type SalesPartner {
  salesPartnerId: ID!
  salesPartnerName: String
}

type Schedule {
  from: LocalDateTime!
  to: LocalDateTime!
}

input ScheduleInput {
  from: LocalDateTime!
  to: LocalDateTime!
}

type ScheduleR0 {
  from: String!
  to: String!
}

input ScheduleWorkOrderInputR0 {
  externalWorkOrderId: String
  schedule: WOScheduleInputR0
  note: NoteInputR0
}

type ScheduleWorkOrderPayloadR0 {
  externalWorkOrderId: String
  schedule: WOScheduleR0!
  note: NoteR0
}

input ScrubAddressInput {
  address: AddressInputR0!
}

type ScrubAddressPayload {
  addressLines: [String!]!
  municipality: String!
  region: String!
  countryCode: String!
  postalCode: String!
  longitude: Float
  latitude: Float
  invalidAddress: Boolean!
  bannedPostalCode: Boolean!
  processStatus: String!
  recommendation: String!
}

type SendLeadToMarketoResponse {
  sendLeadToMarketo: Boolean
}

input ServiceLocationR0 {
  addressLines: [String!]!
  countryCode: String!
  latitude: Float
  longitude: Float
  city: String
  regionOrState: String
  zipOrPostCode: String
}

type ServiceType {
  id: String
  name: String
  kind: String
  description: String
  characteristics: [AttributeR0]
}

type ShoppingCart {
  id: ID!
  status: String!
  cartItems: CartItems!
  prices: ShoppingCartPrices!
  leaseFeeShortName: String!
  bundledAddons: [AddOn!]
}

input ShoppingCartAddonsInput {
  cartId: ID!
  cartName: String
  addonProductIds: [String!]!
  postalCode: String!
}

input ShoppingCartPlanInput {
  cartId: ID
  cartName: String
  planProductId: String!
  location: OFMLocation!
  salesAgreementId: String!
  closingOfferProductId: String
  dealerId: String
  partnerId: String
}

type ShoppingCartPrices {
  monthly: ShoppingCartPricesMonthly!
  oneTime: ShoppingCartPricesOneTime!
}

type ShoppingCartPricesMonthly {
  totalWithPromos: Float
  totalWithoutPromos: Float
  lineItems: lineItemsMonthly
}

type ShoppingCartPricesOneTime {
  totalWithPromos: Float
  totalWithoutPromos: Float
  lease: Float
  lineItems: lineItemsOneTime
  feesAndDiscounts: [FeesAndDiscounts!]
}

type SmbCustomer {
  isCustomer: Boolean!
  existingContactMethod: ExistingContactMethod
  individualPartyId: String!
  relnId: String!
  organizationPartyId: String!
}

enum StateR0 {
  Canceled
  Canceling
  CancelingError
  Closed
  Initializing
  InitializingError
  Open
  OpenError
  Processed
  ProcessedError
  Rejected
  Scheduled
}

input StringSetAttributeInput {
  qualifiedName: ID!
  value: [String!]!
}

input SubmitFullOrderAttributes {
  dealerId: ID
  partnerId: ID
  affiliateId: ID
  sessionId: ID
  campaignId: ID
  adobeEcId: ID
  transactionType: Boolean
  transferConsent: Boolean
  tribalBenefit: Boolean
  responseCode: String
}

input SubmitFullOrderCustomer {
  firstName: String!
  lastName: String!
  dob: DateOfBirth
  serviceLocation: LocationInput!
  billingAddress: AddressInputOM!
  shippingAddress: AddressInputOM!
  taxId: String
  email: String
  phoneNumber: String
}

input SubmitFullOrderInput {
  cartId: ID!
  orderId: ID!
  billingAccountId: ID!
  applicationId: ID
  partyId: ID
  customerRelationshipId: ID!
  customer: SubmitFullOrderCustomer!
  payment: SubmitFullOrderPayment!
  attributes: SubmitFullOrderAttributes!
  workOrderInformation: WorkOrderInformation
  salesAgreementId: String!
  fulfillmentAgreementId: String!
  billingAccountIdToDeactivateList: [String]!
  transactionId: String
  isBusinessAccount: Boolean
}

type SubmitFullOrderPayload {
  orderId: ID!
  externalWorkOrderId: ID!
}

input SubmitFullOrderPayment {
  transactionId: ID!
  paymentType: String!
  userAgent: String!
  paymentOnFileId: String!
}

input SubmitOrderInput {
  orderId: ID!
  customerRelationshipId: ID!
  salesAgreementId: ID!
  fulfillmentAgreementId: ID!
  orderLines: [SubmitOrderLineItemInput!]!
  attributes: [OrderAttributeInput!]
}

input SubmitOrderLineItemInput {
  orderLineId: ID!
  orderLineType: String!
  shoppingCartId: ID!
  taxJurisdiction: TaxJurisdiction
  billingAccountId: ID!
  serviceLocation: LocationInput
  serviceLocationId: ID
  workOrderInformation: WorkOrderInformation
  contractId: ID
}

type Success {
  success: Boolean!
}

type TaxCode {
  taxCodeType: String
  taxCodeValue: String
  label: String
}

input TaxCodeInput {
  label: String
  taxCodeType: String
  taxCodeValue: String
}

input TaxJurisdiction {
  taxCodeType: String!
  taxCodeValue: String!
}

input ThinProductInput {
  id: String!
  kind: String!
  existing: Boolean!
  characteristics: [CharacteristicInput!]!
  name: String
}

type TotalDiscount {
  name: String
  duration: Int
  amount: [Amount]
}

type TransactionPayload {
  relnId: String
  spbBillingAccountId: String
  oneTimeTotal: Int
  userAgent: String
  paymentOnFileId: String!
  transactionId: String
}

input UpdateBillingAccountInput {
  accountNumber: String!
  taxCode: TaxCodeInput!
  paymentReference: String
  isRegulated: Boolean
  isExemptFromLateFee: Boolean
  isExemptFromTaxation: Boolean
}

input UpdateBillingFormationInput {
  relnId: String!
  billingAddress: AddressCSCR0!
  taxCode: TaxCodeInput!
  transactionId: String!
  txnAmount: Int!
}

input UpdateContactMethodInput {
  partyId: ID!
  billingAddressId: ID
  phone: AddPhoneInput!
  email: AddEmailInput!
  address: AddAddressInput!
}

input UpdateIndividualInput {
  partyId: String!
  firstName: String
  lastName: String
  email: String
  phoneNumber: String
  mobileNumber: String
  serviceAddress: AddressInput
  billingAddress: AddressInput
  shippingAddress: AddressInput
  taxId: String
}

type UpdateIndividualResponse {
  updateCreditCheck: Boolean!
}

input UpdateMarketingPreferences {
  relnId: String
  hasCustomerOptedIn: Boolean
}

type UpdateMarketingPreferencesResponse {
  updateMarketingPreferences: Boolean
}

input UpdateOrganizationInput {
  organizationName: String!
  organizationPartyId: String!
  individualPartyId: String!
  firstName: String!
  lastName: String!
  email: String!
  phoneNumber: String!
  mobileNumber: String
  serviceAddress: AddressInput!
  billingAddress: AddressInput
  shippingAddress: AddressInput
  taxId: String
  isTaxIdSsn: Boolean
  isTaxExempt: Boolean
}

type UpdateOrganizationResponse {
  success: Boolean!
}

input UpdateRelationshipIdInput {
  relnId: String!
  accountNumber: String!
  accountNumbersToRemove: [String]
}

input UpdateVPPTransactionInput {
  customerRef: String
  spbBillingAccountId: String
  transactionId: String
  txnAmount: Int
  userAgent: String
  blockedPaymentMethods: [String]
  email: String
  billingAddress: AddressInput
}

type VPPAuthVoidResponse {
  transactionId: String
  authVoidSuccessful: Boolean
}

type VPPRetrievePaymentPayload {
  userAgent: String
  txnType: String
  txnStatus: String
  txnId: String
  txnAmount: Int
  systemName: String
  secondaryTxns: [String]
  returnURL: String
  rescueStatus: String
  recurringPayment: Boolean
  paymentSource: String
  paymentProduct: String
  paymentMethod: String
  paymentInfoSource: String
  parentTxn: String
  LastModifiedDate: Date
  languageCode: String
  email: String
  customerRef: String
  currencyIsoCode: String
  createdDate: String
  correlationId: String
  cancelURL: String
  billingAddress: NullableAddress
  additionalDetails: String
}

type VPPSubmitSalePayload {
  success: Boolean
}

type VPPTransactionPayload {
  transactionId: String
}

input WOScheduleInputR0 {
  from: String!
  to: String!
}

type WOScheduleR0 {
  from: String!
  to: String!
}

input WorkOrderInformation {
  appointment: Appointment!
  notes: String
  onSiteContactInformation: OnSiteContactInformation
}

input WorkOrderInput {
  externalWorkOrderId: String
  schedule: WOScheduleInputR0
  note: NoteInputR0
  skipWorkOrder: Boolean
}

type WorkOrderType {
  workOrderTypeId: ID!
  workOrderType: String!
}

type addBillingAccountResponse {
  billingAcount: BillingAccount
}

type lineItemsMonthly {
  promo: Float!
  lease: Float
}

type lineItemsOneTime {
  promo: Float
}

`;
